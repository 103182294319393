import React from 'react'
import Layout from '../components/layout/layout'
import SectionLeft from '../components/sections/section-number-left'
import SectionRight from '../components/sections/section-number-right'
import { SectionLast } from '../components/sections/section-last'
import TextBold from '../components/sections/components/text-bold'
import Text from '../components/sections/components/text'
import Footer from '../components/footer'
import NewsOnePlace from '../images/news_one_place.svg'
import FindFast from '../images/find_fast.svg'
import WhyReg from '../images/why_reg.svg'
import GetFastInfo from '../images/get_fast_info.svg'
import { injectIntl, IntlFormatters } from 'gatsby-plugin-intl'
import { SectionWithoutImage } from '../components/sections/section-without-image'
import { SectionIcons } from '../components/sections/section-icons'
import TimeSaving from '../images/time_saving.svg'
import AccurateResults from '../images/accurate_results.svg'
import Information from '../images/information.svg'
import RelevantInfromation from '../images/relevant_information.svg'

const ForUser = ({ intl }: IntlFormatters) => {
  return (
    <Layout>
      <SectionLeft
        heading={intl.formatMessage({ id: 'forUser.sectionOne.heading' })}
        text={
          <>
            <Text>
              {intl.formatMessage({ id: 'forUser.sectionOne.text1' })}
              <TextBold>{intl.formatMessage({ id: 'forUser.sectionOne.text2' })} </TextBold>
            </Text>
            <Text>{intl.formatMessage({ id: 'forUser.sectionOne.text3' })}</Text>
          </>
        }
        SvgImage={GetFastInfo}
      />
      <SectionRight
        heading={intl.formatMessage({ id: 'forUser.sectionTwo.heading' })}
        list={
          <span>
            <li>
              <Text>{intl.formatMessage({ id: 'forUser.sectionTwo.text1' })}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage({ id: 'forUser.sectionTwo.text2' })}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage({ id: 'forUser.sectionTwo.text3' })}</Text>
            </li>
          </span>
        }
        SvgImage={NewsOnePlace}
      />
      <SectionLeft
        heading={intl.formatMessage({ id: 'forUser.sectionThree.heading' })}
        list={
          <span>
            <li>
              <Text>{intl.formatMessage({ id: 'forUser.sectionThree.text1' })}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage({ id: 'forUser.sectionThree.text2' })}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage({ id: 'forUser.sectionThree.text3' })}</Text>
            </li>
          </span>
        }
        SvgImage={FindFast}
      />
      <SectionWithoutImage
        heading1={intl.formatMessage({ id: 'forUser.sectionFour.heading' })}
        text1={
          <Text>
            {intl.formatMessage({ id: 'forUser.sectionFour.text1' })}
            <TextBold> {intl.formatMessage({ id: 'forUser.sectionFour.text2' })}</TextBold>
            {intl.formatMessage({ id: 'forUser.sectionFour.text3' })}
          </Text>
        }
        heading2={intl.formatMessage({ id: 'forUser.sectionFive.heading' })}
        text2={
          <>
            <Text>{intl.formatMessage({ id: 'forUser.sectionFive.text1' })}</Text>
            <Text>
              {intl.formatMessage({ id: 'forUser.sectionFive.text2' })}
              <TextBold>{intl.formatMessage({ id: 'forUser.sectionFive.text3' })}</TextBold>
              {intl.formatMessage({ id: 'forUser.sectionFive.text4' })}
            </Text>
          </>
        }
      />

      <SectionIcons
        svgImages={[<TimeSaving />, <AccurateResults />, <Information />, <RelevantInfromation />]}
        texts={[
          <Text>{intl.formatMessage({ id: 'forUser.sectionSix.text1' })}</Text>,
          <Text>{intl.formatMessage({ id: 'forUser.sectionSix.text2' })}</Text>,
          <Text>{intl.formatMessage({ id: 'forUser.sectionSix.text3' })}</Text>,
          <Text>{intl.formatMessage({ id: 'forUser.sectionSix.text4' })}</Text>,
        ]}
      />
      <SectionLast
        heading={intl.formatMessage({ id: 'forUser.sectionEight.heading' })}
        list={
          <span>
            <li>
              <Text>
                {intl.formatMessage({ id: 'forUser.sectionEight.text1' })}
                <TextBold>{intl.formatMessage({ id: 'forUser.sectionEight.text2' })}</TextBold>
              </Text>
            </li>
            <li>
              <Text>{intl.formatMessage({ id: 'forUser.sectionEight.text3' })}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage({ id: 'forUser.sectionEight.text4' })}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage({ id: 'forUser.sectionEight.text5' })}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage({ id: 'forUser.sectionEight.text6' })}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage({ id: 'forUser.sectionEight.text7' })}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage({ id: 'forUser.sectionEight.text8' })}</Text>
            </li>
          </span>
        }
        SvgImage={WhyReg}
        button={{
          text: intl.formatMessage({ id: 'forUser.sectionEight.textButton' }),
          link: intl.formatMessage({ id: 'link.gcms' }),
        }}
      />
      <Footer />
    </Layout>
  )
}

export default injectIntl(ForUser)
