import React from 'react'
import styled from 'styled-components'
import { colors } from '../styles/variables'
import { sizes } from '../styles/sizes'
import { injectIntl, IntlFormatters } from 'gatsby-plugin-intl'
import Info from '../components/footer/components/info'
import SocialMedia from '../components/footer/components/social-media'

const FooterWrapper = styled.footer`
  height: 270px;
  display: flex;
  justify-content: space-between;
  padding: 0 20rem;
  color: ${colors.light};

  padding-top: 10px;
  background-color: ${colors.black};

  padding-top: 8px;
  background: linear-gradient(to right, #ffc107 0%, #f27405 22%, #d92b04 42%, #be0f8f 66%, #2975d9 88%, #2fc3d4 100%)
    left top ${colors.black} no-repeat;
  background-size: 100% 8px;

  z-index: 60;
  @media (max-width: ${sizes.breakpointsMax.xl}) {
    justify-content: space-evenly;
    padding: 0;
  }
  & .wrapper {
    position: relative;
    width: 200px;
    padding-top: 50px;
    margin-right: 50px;
    margin-left: -25px;
    @media (max-width: ${sizes.breakpointsMax.lg}) {
      margin-right: 0;
      margin-left: 0;
    }
  }
`

const Footer = ({ intl }: IntlFormatters) => {
  return (
    <FooterWrapper>
      <div className="wrapper">
        <Info intl={intl} lighter={true} />
      </div>
      <div className="wrapper">
        <SocialMedia intl={intl} />
      </div>
    </FooterWrapper>
  )
}

export default injectIntl(Footer)
