import styled from 'styled-components'
import { sizes } from '../../../styles/sizes'

export const Row = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  gap: 9.5rem;
  @media (max-width: ${sizes.breakpointsMax.hg}) {
    gap: 6.5rem;
  }
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    flex-direction: column;
    height: 1100px;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    flex-direction: column;
    gap: 1rem;
    height: 850px;
  }
`
export const RowReverse = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  gap: 9.5rem;
  @media (max-width: ${sizes.breakpointsMax.hg}) {
    gap: 6.5rem;
  }
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    flex-direction: column-reverse;
    height: 1100px;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    flex-direction: column-reverse;
    gap: 1rem;
    height: 750px;
  }
`
export const SvgContainer = styled.div`
  .image {
    @media (max-width: ${sizes.breakpointsMax.hg}) {
      width: 500px;
      height: 500px;
    }
    @media (max-width: ${sizes.breakpointsMax.lg}) {
      width: 500px;
      height: 500px;
    }
    @media (max-width: ${sizes.breakpointsMax.sm}) {
      width: 274px;
      height: 274px;
    }
  }
`
export const TextWrapper = styled.div`
  width: 100%;
  height: 100%;
`
export const Box = styled.div`
  position: relative;
`
