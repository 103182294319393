import React, { ComponentType, ReactElement } from 'react'
import Container from './components/container'
import ContainerList from './components/container-list'
import { Row, SvgContainer, TextWrapper, Box } from './components/row'

interface SectionLeftNumber {
  heading: string
  text?: ReactElement
  list?: ReactElement
  SvgImage?: ComponentType<{ className: string }>
}

const SectionNumberLeft = (props: SectionLeftNumber) => {
  const { heading, text, list, SvgImage } = props
  return (
    <Row>
      {SvgImage && (
        <SvgContainer>
          <SvgImage className="image" />
        </SvgContainer>
      )}
      <Box>
        <TextWrapper>
          {text && <Container heading={heading} text={text} />}
          {list && <ContainerList heading={heading}>{list}</ContainerList>}
        </TextWrapper>
      </Box>
    </Row>
  )
}

export default SectionNumberLeft
