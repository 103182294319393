import React from 'react'
import styled from 'styled-components'
import { sizes } from '../../../styles/sizes'

const Grandient = styled.div`
  width: 100%;
  height: 8px;
  background: transparent
    linear-gradient(90deg, #ffc107 0%, #f27405 22%, #d92b04 42%, #be0f8f 66%, #2975d9 88%, #2fc3d4 100%) 0% 0% no-repeat
    padding-box;
  opacity: 1;

  @media (max-width: ${sizes.breakpointsMax.lg}) {
    width: 100%;
  }
`

const GrandientLine = () => {
  return <Grandient />
}

export default GrandientLine
