import React, { ComponentType, ReactElement } from 'react'
import Container from './components/container'
import ContainerList from './components/container-list'
import { RowReverse, SvgContainer, TextWrapper, Box } from './components/row'

interface SectionLeftNumber {
  heading: string
  text?: ReactElement
  list?: ReactElement
  SvgImage?: ComponentType<{ className: string }>
}

const SectioNumberRight = (props: SectionLeftNumber) => {
  const { heading, text, list, SvgImage } = props
  return (
    <RowReverse>
      <Box>
        <TextWrapper>
          {text && <Container heading={heading} text={text} />}
          {list && <ContainerList heading={heading}>{list}</ContainerList>}
        </TextWrapper>
      </Box>
      {SvgImage && (
        <SvgContainer>
          <SvgImage className="image" />
        </SvgContainer>
      )}
    </RowReverse>
  )
}

export default SectioNumberRight
