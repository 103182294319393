import React, { ReactNode, CSSProperties } from 'react'
import styled from 'styled-components'
import { fontStyles } from '../../../styles/variables'
import { sizes } from '../../../styles/sizes'

interface IProps {
  children: ReactNode
  style?: CSSProperties | undefined
  big?: boolean
}

const HeadingStyled = styled.h1`
  max-width: 410px;
  ${fontStyles.heading};
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    width: 100%;
    max-width: 520px;
  }
`
const HeadingStyledBig = styled.h1`
  max-width: 410px;
  ${fontStyles.bigHeading};
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    width: 100%;
    max-width: 520px;
  }
`
const Heading = (props: IProps) => {
  const { children, style, big, ...rest } = props

  return big ? (
    <HeadingStyledBig style={style} {...rest}>
      {children}
    </HeadingStyledBig>
  ) : (
    <HeadingStyled style={style} {...rest}>
      {children}
    </HeadingStyled>
  )
}

export default Heading
