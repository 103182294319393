import React, { CSSProperties, ReactElement } from 'react'
import styled from 'styled-components'
import GrandientLine from './grandient-line'
import Heading from './heading'
import { sizes } from '../../../styles/sizes'
import { fontStyles } from '../../../styles/variables'

interface ContainerIProps {
  text: ReactElement
  style?: CSSProperties | undefined
  heading?: string
  subheading?: string
  color?: string
  big?: boolean
}

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 472px;
  @media (max-width: ${sizes.breakpointsMax.xl}) {
    width: 424px;
  }
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    width: 520px;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    max-width: 274px;
    gap: 1.5rem;
    padding-top: 2rem;
  }
`
export const ColorLine = styled.div<{ $color: string }>`
  width: 100%;
  height: 8px;
  background: ${(p) => p.$color};
  opacity: 1;
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    width: 100%;
  }
`
export const Subheading = styled.h1`
  max-width: 480px;
  ${fontStyles.subheading};
`
export const SubheadingColored = styled.h1<{ $color: string }>`
  max-width: 480px;
  ${fontStyles.subheading};
  color: ${(p) => p.$color};
`

const Container = (props: ContainerIProps) => {
  const { subheading, heading, text, color, big, ...rest } = props
  return (
    <ContainerStyled {...props} {...rest}>
      {subheading &&
        (color ? (
          <SubheadingColored $color={color}>{subheading}</SubheadingColored>
        ) : (
          <Subheading>{subheading}</Subheading>
        ))}
      {heading && <Heading big={big}>{heading}</Heading>}
      {color ? <ColorLine $color={color} /> : <GrandientLine />}
      {text}
    </ContainerStyled>
  )
}

export default Container
