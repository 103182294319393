import React, { ComponentType, ReactElement } from 'react'
import Container from './components/container'
import ButtonHref from './components/button-href'
import ContainerList from './components/container-list'
import { Button } from '../types'
import { SvgContainer, TextWrapper, Box } from './components/row'
import styled from 'styled-components'
import { sizes } from '../../styles/sizes'

interface SectionLeftNumber {
  heading: string
  text?: ReactElement
  list?: ReactElement
  SvgImage?: ComponentType<{ className: string }>
  button: Button
}

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  gap: 9.5rem;
  @media (max-width: ${sizes.breakpointsMax.hg}) {
    gap: 6.5rem;
  }
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    flex-direction: column;
    height: 1550px;
    padding-top: 4rem;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    flex-direction: column;
    gap: 1rem;
    height: 1200px;
    justify-content: flex-start;
  }
`

export const SectionLast = (props: SectionLeftNumber) => {
  const { heading, text, list, SvgImage, button } = props
  return (
    <Wrapper>
      {SvgImage && (
        <SvgContainer>
          <SvgImage className="image" />
        </SvgContainer>
      )}
      <Box>
        <TextWrapper>
          {text && <Container heading={heading} text={text} />}
          {list && <ContainerList heading={heading}>{list}</ContainerList>}
        </TextWrapper>
        <ButtonHref text={button.text} to={button.link} />
      </Box>
    </Wrapper>
  )
}
